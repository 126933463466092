import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Page from "../components/Page"
import FancyHeader from "../components/blocks/FancyHeader"
import ButtonLink from "../components/extensions/ButtonLink"

const NotFoundPage = () => (
  <Page zeroTop>
    <FancyHeader
      title={"Page Not Found"}
      subTitle={"This page doesn't exist 😔"}
    />
    <Container>
      <Row>
        <Col xs={12} sm={6}>
          <ButtonLink size="lg" variant="outline-light" to={"/"}>
            {"Go Home"}
          </ButtonLink>
          <br />
        </Col>
      </Row>
    </Container>
  </Page>
)

export default NotFoundPage
